exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-blog-aboutus-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/aboutus.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-blog-aboutus-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-blog-allyouneedblog-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/allyouneedblog.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-blog-allyouneedblog-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-blog-companycantrust-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/companycantrust.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-blog-companycantrust-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-blog-higeststandart-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/higeststandart.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-blog-higeststandart-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-firstcard-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/data/firstcard/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-firstcard-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-fivecard-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/data/fivecard/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-fivecard-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-fourcard-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/data/fourcard/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-fourcard-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-secondcard-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/data/secondcard/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-secondcard-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-sixcard-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/data/sixcard/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-sixcard-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-thirdcard-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/data/thirdcard/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-data-thirdcard-index-mdx" */),
  "component---src-pages-whychooseus-js": () => import("./../../../src/pages/whychooseus.js" /* webpackChunkName: "component---src-pages-whychooseus-js" */)
}

